.backgroundImage {
  background-image: url('https://cdn.raduo.cloud/website/home-background.jpg');
  
  height: 100%;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textContainer {
  background-color: #80808033;
  border: 4px solid #80808099;
  border-radius: 20px;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.signInText {
  font-family: 'Montserrat';
  font-size: 2rem;
  color: '#1b1b1b';
}

.spinnerContainer {
  margin-bottom: 10px;
}