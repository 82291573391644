.container {
  margin: 0 1rem;
}

.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button {
  cursor: pointer;
}

.buttonText {
  color: var(--blue);
}

.textInputContainer {
  width: 100%;
}

.textInputContainer>textarea {
  width: 100%;
  min-height: 200px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row>* {
  margin: 0 10px;
}

.deleteText {
  color: red;
}