.container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--grey5);
  opacity: 0.6;
  border: 1px solid black;
}

.container:hover {
  opacity: 0.4;
}