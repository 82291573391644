.answerContainer {
  flex: 35%;
  border: 0.1rem solid var(--grey4);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  padding: 0 2rem;
  min-height: 5vh;
  margin: 5px;
  cursor: pointer;
}

@media screen and (max-width: 580px) {
  .answerContainer {
    padding: 0;
  }
}

.answerContainer>h4 {
  color: var(--grey1);
  font-weight: 500;
}

.answerPhoto {
  max-width: 35vw;
  max-height: 20vh;
  width: auto;
  height: auto;
  margin: 1rem;
}

.answerNeutral {
  background-color: var(--grey7);
}

.answerSelected {
  background-color: var(--light-blue);
}

.answerCorrect {
  background-color: var(--correct);
}

.answerIncorrect {
  background-color: var(--incorrect);
}

.answerImageContainer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.zoomButton {
  position: absolute;
  bottom: 5px;
  right: 5px;
}