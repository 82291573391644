.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.backText {
  margin-top: 1rem;
  margin-left: 1rem;
  color: blue;
  cursor: pointer;
}