.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--navbar-height);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-image: linear-gradient(#6b6b6b, #4a4a4a);
}

.logoContainer {
  cursor: pointer;
  padding-left: 1rem;
  margin-bottom: 0.4rem;
}

.logoText {
  color: var(--logo);
  font-family: 'Maven Pro';
  font-size: 3rem;
}

.nameContainer {
  cursor: pointer;
  padding-right: 1rem;
}

.nameText {
  font-family: 'Montserrat';
  color: white;
  font-size: 1rem;
}

.userMenu {
  min-width: 200px;
  background-color: white;
  position: absolute;
  top: calc(var(--navbar-height) + 0.2rem);
  right: 0.2rem;
  z-index: 1;
  border: 2px solid var(--grey5);
  border-radius: 5px;
  z-index: 200;
}

.userMenuOption {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  padding: 10px 1rem;
}

.userMenuOption:hover {
  background-color: var(--grey6);
}

.dismissHandler {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}