.popupModalContainer {
  padding: 1rem 2rem;
}

.optionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.questionText {
  text-align: center;
}

.optionButton {
  padding: 0.5rem 1rem;
  background-color: var(--light-blue);
  cursor: pointer;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.optionButton:hover {
  opacity: 0.9;
}

.optionButtonText {
  font-weight: 500;
}

.showAgainContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.showAgainContainer>input {
  width: 1rem;
  flex-grow: 0;
}