.container {
  border-radius: 5px;
  background-color: var(--light-blue);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(90vw, 400px);
}

.description {
  margin-bottom: 10px;
}

.detailText {
  margin-bottom: 10px;
  font-style: italic;
}

.scoreText {
  margin-bottom: 10px;
  font-weight: 500;
}

.button {
  border: 1px solid var(--black-blue);
  border-radius: 10px;
  background-color: var(--blue);
  padding: 4px 20px;
  cursor: pointer;
  width: min(300px, 80%);
}

.button:not(:first-of-type) {
  margin-top: 10px;
}

.button:hover {
  opacity: 0.9;
}

.button>h3 {
  color: var(--white);
  text-align: center;
}