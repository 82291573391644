.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.lessonRow {
  padding: 0.3rem 1rem;
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid black;
  cursor: pointer;
}


.lessonRow:hover {
  background-color: lightgray;
}

.orderText {
  color: grey;
  font-family: monospace;
}

.lessonImage {
  width: 1rem;
  height: 1rem;
  margin: 0 1rem;
}

.titleText {
  flex-grow: 1;
}

.visibilityText {
  flex-grow: 100;
  color: grey;
}