.lessonItemContainer {
  background-color: var(--off-white);
  width: 25vw;
  min-width: 170px;
  height: 25vw;
  min-height: 170px;
  border-radius: calc(max(170px, 25vw) / 2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid var(--grey4);
  margin: 1rem;
}

.lessonItemVisible {
  cursor: pointer;
  border-width: 3px;
}

.lessonItemVisible:hover {
  opacity: 0.8;
}

.lessonItemPreview {
  cursor: default;
  opacity: 0.5;
}

.lessonItemLocked {
  opacity: 0.5;
  cursor: default;
  background-color: var(--grey6);
  border: 1px solid var(--grey5) !important;
}

.lessonComplete {
  border: 2px solid var(--correct);
  background-color: var(--correct-light);
}

.lessonInProgress {
  border: 2px solid var(--light-blue);
  background-color: var(--light-blue-light);
}

.lessonImage {
  width: 25%;
  height: auto;
}

.lessonTitleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 45%;
  margin: 0 1rem;
}

.lessonTitle {
  text-align: center;
  font-size: max(2vw, 16px);
}

.modalContainer {
  background-color: white;
  border: 2px solid grey;
}

.flexContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}