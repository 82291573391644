.questionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.questionText {
  margin-bottom: 10px;
  text-align: center;
}

.questionImageContainer {
  position: relative;
  display: flex;
}

.sectionPhotoContainer {
  position: relative;
}

.questionImage {
  height: 40vh;
  width: auto;
}

@media screen and (max-width: 580px) {
  .questionImage {
    max-height: 30vh;
  }
}

.answersContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.nextButtonContainer {
  display: flex;
  padding: 0.5rem 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 400px;
}

.nextButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--blue);
  height: 3rem;
  width: 100%;
  border-radius: 1em;
}

.nextButton>h3 {
  color: white;
  font-weight: 600;
}

.disabledButton {
  background-color: var(--grey5);
  cursor: default;
}

.zoomButton {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.zoomButtonSectioned {
  position: absolute;
  bottom: 5px;
  left: calc(100% + 5px);
}