

.container {
  --circle-size: min(90vw, 350px);
  width: var(--circle-size);
  height: var(--circle-size);
  transition: opacity 0.5s;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 2;
}

.lessonImage {
  width: calc(var(--circle-size) / 3);
  height: calc(var(--circle-size) / 3);
}

.numericScoreContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.5s;
}

.numericScore {
  font-size: 2rem;
}

.percentScoreContainer {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: opacity 0.5s;
}

.percentScore {
  font-size: 1.6rem;
}