.container {
  display: flex;
  flex-direction: column;
}

.progressBarContainer {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80vw;
}

.currentProgressContainer {
  display: inline;
  margin-top: 2px;
  width: 80vw;
}

.currentProgress {
  background-color: var(--highlight);
  height: 6px;
  border-radius: 3px;
}

.progressSegmentBase {
  height: 20px;
  margin: 0 1px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to left, var(--grey6) 50%, var(--light-blue) 50%) right;
  background-size: 200%;
  transition: .5s ease-out;
}

.progressSegmentClickable {
  cursor: pointer;
}

.progressSegmentGuessed {
  background-position: left;
}

.progressSegmentBase:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.progressSegmentBase:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.progressEmoji {
  font-size: 10px;
}