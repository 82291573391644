.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
}

.iconContainer {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconButton {
  cursor: pointer;
}

.lessonTitle {
  text-align: center;
}

.questionContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 2rem;
}

@media screen and (max-width: 580px) {
  .questionContainer {
    padding: 0 0.5rem;
  }
}

.questionHidden {
  opacity: 0;
}

.lessonSummaryButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.lessonSummaryButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--blue);
  height: 3rem;
  width: 90%;
  border-radius: 1em;
  max-width: 400px;
}

.lessonSummaryButton>h3 {
  color: white;
  font-weight: 600;
}