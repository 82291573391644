.container {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  width: 95%;
}

.moduleContainer {
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-top: 1px solid black;
  border-radius: 2px;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 0.2rem 1rem;
}

.moduleContainer:last-child {
  border-bottom: 1px solid black;
}

.image {
  width: 2rem;
  height: 2rem;
}

.edit {
  justify-self: end;
  cursor: pointer;
  margin: 0 5px;
  color: blue;
}

.visibilityText {
  color: grey;
  margin-left: 0.3rem;
}