.flexContainer {
  flex-basis: 32%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  opacity: 1;
  background-color: var(--grey5);
  border-radius: 1rem;
  border: 2px solid var(--grey4);
  margin: 1rem 0;
}

.previewContentContainer {
  cursor: default;
  opacity: 0.5;
}

.visibleContentContainer {
  cursor: pointer;
}

.visibleContentContainer:hover {
  background-color: var(--grey6);
}

.moduleImage {
  width: 5rem;
  height: 5rem;
}