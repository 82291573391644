.outerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 95%;
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2px 0;
}

.label {
  width: 100px;
}

input {
  flex-grow: 1;
}

.answersContainer {
  margin-top: 1rem;
  border-top: 1px solid black;
}

.answerContainer {
  border-bottom: 1px solid black;
  padding: 10px 0;
}

.image {
  width: 4rem;
  height: 4rem;
}

.sectionPhotoImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sectionPhotoImage {
  width: 50vw;
  height: 50vw;
}

.dimensionInputs>input {
  width: 2rem;
}

.createContainer {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.createButton {
  cursor: pointer;
  color: blue;
}

.errorText {
  color: red;
  text-align: center;
}

.deleteButton {
  cursor: pointer;
}