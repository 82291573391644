.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.numberContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 2rem;
}

.arrow {
  cursor: pointer;
}

.questionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.photoAndAnswers {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.image {
  width: 4rem;
  height: 4rem;
}

.answers {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.correctAnswer {
  color: green;
  font-weight: bold;
}