.moduleInfoContainer {
  padding: 10px;
}

.moduleInfoTable>tr>td>div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tableModulePhoto {
  width: 2rem;
  height: 2rem;
}

.centeredContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.editButton {
  cursor: pointer;
}

.editText {
  color: blue;
}