.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lessonTitle {
  margin-top: 1rem;
  padding: 0 1rem;
  text-align: center;
}

.circleContainer {
  padding: 1rem;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  width: min(90vw, 350px);
  transition: opacity 0.5s;
}

.resultTextContainer {
  transition: opacity 0.5s;
}

.passingButton,
.failureButton,
.reviewButton {
  border: 1px solid var(--grey5);
  background-color: white;
  color: var(--grey0);
  padding: 1rem;
  margin: 0.4rem;
}

.passingButton:hover,
.failureButton:hover,
.reviewButton:hover {
  background-color: var(--grey6);
}