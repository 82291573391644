.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.numberContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 2rem;
}

.arrow {
  cursor: pointer;
}

.answerPhotos {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.answer,
.correctAnswer {
  height: 4rem;
  width: 4rem;
  padding: 0 5px;
}

.correctAnswer {
  border: 5px solid green;
}