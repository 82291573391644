.lessonRow {
  margin: 2vw 0;
  padding: 0 min(100px, 20vw);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.headerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.moduleTitle {
  text-align: center;
}