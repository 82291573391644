.backgroundImage {
  background-image: url('https://cdn.raduo.cloud/website/home-background.jpg');

  height: 100%;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signInContainer {
  background-color: #80808033;
  border: 4px solid #80808099;
  border-radius: 20px;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 20px;
}

.logoText {
  font-family: 'Maven Pro';
  font-size: 3rem;
  color: var(--logo);
  margin-bottom: 10px;
}

.header {
  margin-top: 0;
}


.googleButton,
.facebookButton {
  margin: 5px 0;
  width: max(50%, 220px);
  padding: 0.5rem 0.7rem;
  border-radius: 0.8rem;
  display: flex;
  justify-content: center;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
}

.googleButton {
  background-color: #1aa260;
}

.facebookButton {
  margin-bottom: 0;
  background-color: #3b5998;
}

.googleButton:hover,
.facebookButton:hover {
  cursor: pointer;
  opacity: 0.90;
}

.signInText {
  text-align: center;
  font-family: 'Montserrat';
  color: white;
}