.table {
  margin-left: 1rem;
}

.photo {
  width: 2rem;
  height: 2rem;
}

.centeredRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.mono {
  font-family: monospace;
}