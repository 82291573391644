.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
}

.markdownContent {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.markdownContent p {
  margin-bottom: 1rem;
}

.continueButton {
  margin-top: 2rem;
  margin-bottom: 1rem;
  cursor: pointer;
  background-color: var(--blue);
  border-radius: 1rem;
  padding: 1rem 2rem;
}

.continueButton:hover {
  opacity: 0.8;
}

.continueText {
  color: white;
  font-weight: 400;
}