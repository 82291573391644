.modalShading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--grey0);
  opacity: 0.8;
}

.modalContainer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.modalContent {
  border-radius: 1rem;
  background-color: var(--white);
  width: 98%;
  height: 98%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dismissContainer {
  display: flex;
  flex-direction: row-reverse;
}

.closeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.modalImageContainer {
  align-self: center;
  max-height: 80%;
}


.modalImage {
  max-height: 100%;
}

.okayContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.okayButton {
  margin: 1rem;
  background-color: var(--grey5);
  font-weight: bold;
  padding: 0.2rem 1rem;
  border-radius: 0.4rem;
  cursor: pointer;
}

.okayButton:hover {
  opacity: 0.7;
}