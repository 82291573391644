.contentContainer {
  position: absolute;
  top: var(--navbar-height);
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
  overflow-y: scroll;
}

.footer {
  background-color: #4a4a4a;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.feedbackButton {
  padding: 10px;
  cursor: pointer;
}

.feedbackText {
  color: white;
  font-weight: 500;
}