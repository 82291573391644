.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.headerContainer {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerText {
  text-align: center;
}

.modulesContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0 4vw;
}

.attributionLink {
  align-self: center;
}