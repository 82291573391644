#root {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: 'Maven Pro';
  src: url('./fonts/Maven-Pro.ttf');
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat.ttf');
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/Open-Sans.ttf');
}

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-family: 'Open Sans';
  color: var(--heading);
  font-weight: 300;
}

@media screen and (max-width: 580px) {
  h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1.17em;
  }

  h3 {
    font-size: 1em;
  }

  h4,
  p {
    font-size: 0.83em;
  }
}

button {
  background-color: #1520a6;
  border: none;
  color: white;
  padding: 0.5rem 0.7rem;
  border-radius: 0.8rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 600;
}

button:hover {
  cursor: pointer;
  opacity: 0.90;
}

table,
th,
td {
  border: 1px solid;
}

table {
  border-collapse: collapse;
}

td {
  padding: 5px;
}