.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 95%;
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputRow {
  display: flex;
  flex-direction: row;
  margin: 2px 0;
}

.label {
  width: 100px;
}

input {
  flex-grow: 1;
}

.createContainer {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.createButton {
  cursor: pointer;
  color: blue;
}

.errorText {
  color: red;
  text-align: center;
}