#root {
  --logo: #0033cc;

  --grey0: #222222;
  --grey1: #444444;
  --grey2: #666666;
  --grey3: #888888;
  --grey4: #aaaaaa;
  --grey5: #cccccc;
  --grey6: #eeeeee;
  --off-white: #f9f9f9;
  --white: #ffffff;

  --heading: #222;

  --black-blue: #020024;
  --dark-blue: #090979;
  --blue: #0033cc;
  --light-blue: #c2d1ee;
  --light-blue-light: #e5eeff;

  --correct: #0ad841;
  --correct-light: #ecfff2;
  --incorrect: #bf6860;
  --highlight: #ffd700;

  --navbar-height: 4rem;
}