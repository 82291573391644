.centeredContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button {
  cursor: pointer;
}

.buttonText {
  color: blue;
}