.shading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--grey0);
  opacity: 0.8;
  z-index: 998;
}

.content {
  position: fixed;
  border-radius: 1rem;
  background-color: var(--white);
  z-index: 1000;
  scrollbar-width: none;
}

.content::-webkit-scrollbar {
  display: none;
}

.fullScreenContent {
  top: calc(0px + 1%);
  left: calc(0px + 1%);
  width: 98%;
  height: 98%;
  overflow-y: scroll;
}

.popupContent {
  min-width: 50%;
  min-height: 4rem;
  top: 20%;
  align-self: center;
}

.closeContainer {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.closeButton {
  cursor: pointer;
  margin: 0.3rem;
}