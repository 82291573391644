.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.displayHeader {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.displayTitle {
  align-self: flex-start;
}

.refresh {
  cursor: pointer;
  color: blue;
}

.add {
  color: blue;
  cursor: pointer;
}