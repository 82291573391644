.container {
  position: relative;
  display: flex;
}

.image {
  display: block;
  height: auto;
}

.overlayGrid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
}